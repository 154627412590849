import { createContext } from "react";
import swal from "sweetalert";
import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3Auth } from "@web3auth/modal";
import { WalletServicesPlugin } from "@web3auth/wallet-services-plugin";
export const MyContext = createContext();


//Live
// export const apiUrl = "https://api.betfolio.co/api/v1"
// export const baseUrl = "https://api.betfolio.co/"
// export const chainId = 137;
// export const tokenAddress = "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359";//live mainnet
// export const consttransactionurl = "https://polygonscan.com/tx/";
// export const metamaskUrl = "https://metamask.app.link/dapp/betfolio.co/"
// export const divideBy = 1000000;
// export const decimalTo = 6;
// export const betCounterAddress = "0x88CD2402f3A486DCd2364c0b0BB9b2C81546b8A3"
// export const soloCounterAddress = "0x1fAA3c5d8692fb1571f8D433B39A1484765cbfD5"
// export const soloNft = "0x34107Deb9348Aa4daA8e8fAa5dAcaDe2FbbdAFF1"
// export const soloExtendedNft = "0xbeaE75BBd61AC96f1DA1ea07FA1eF8473cF0EAf7"
// export const groupNft = "0x2A7f6Cca4BbAa8a3cd12A1dE9c8e15fEF0F31D01"
// export const multiChoiceNft = "0xF3DcF23919136107535b2728E4627087C4d22b71"
// export const multiChoiceBetCounter = "0x639cc78918a57d43aaf948fde0d02f95055d4005"
// export const appUrl = "https://betfolio.co"
// export const folioContractAddress = '0x2877BC2369a42634becb2d9A4dCf77D97a7230C4';
// export const nftMinterAddress = "0x8C3fc7D87081eD1eD080EAe22f0E68Fd3F866006" // new minter address
// export const WEB3_PROVIDER = "https://polygon-mainnet.g.alchemy.com/v2/8gDakbIcC4LVMnBpF_KG94Z8dNp49pb3"
// export const timeExtenderAddress = "0x6598618a777fd5fD70c9e2020A00786F1bfeFc24"
// export const NftBuyContractAddress = '0xc6ba950917be27d13e31ada1a42c262ff73077ad';


// export const soloTimeExtensionNFT_01_BETS_ADDRESS = "0xDEf65AbC4a23a4c9baff68bFACc2606CF17A5d49";
// export const soloTimeExtensionNFT_03_BETS_ADDRESS = "0x0396520B7F7e60e568482d8E50F610569cfbc3cE";
// export const soloTimeExtensionNFT_10_BETS_ADDRESS = "0x7A450AC0410F161Cb1837127eAfF50820D8e31d9";
// export const multiTimeExtensionNFT_01_BETS_ADDRESS = "0xCCad03162E35EF455716A537EEF0F52899a7D3CB";
// export const multiTimeExtensionNFT_20_BETS_ADDRESS = "0x43131f9B25E636563Cf3851E603edE566901A105";
// export const nftAllContract = "0x6162F21993095321317f3000Df0FA32F60A9C96B";
// export const discountContract = "0x746AA9F8d3F6A33435174CB0560D78bdC679E8da";
// export const clientId = "BEBmKk7gbN6wk-bM8f01LYUGUEKNjkU-quhFUHEE7MKJK01SlH6hNubSoa0zB8ZjvjWsZEwz2jNwrjRvgWooq08" //MAINNET live
// export const nftBundlingContract = "0xd31b4CB987534B496386fF287A279f84BCeb1010";
// export const nftBundlingListingFee = 0.2;
// export const nftBundlingBuyingFee = 0.2;
// export const nftBundlingRelistingFee = 0.2;
// export const nftBundlingUpdatePricingFee = 0.1;
// export const PAYPAL_CLIENT_ID="AXcewEpeqHNvGkY0GOAbboIyH1XutDJhc_9dtIOhIHGtlOzvTxLYiUsFljvcEhRHz0GEIyuc1NkhUyIe";
// export const mintNftsList = [

//   // {
//   //   mintId: 0,
//   //   mintName: "Group NFT",
//   //   tokenValue: 4,
//   //   contract: groupNft,
//   //   contractType: "GROUP",
//   //   bets: 0
//   // },
//   // {
//   //   mintId: 1,
//   //   mintName: "BetFolio Solo Prediction Pass",
//   //   tokenValue: 1.5,
//   //   contract: soloNft,
//   //   contractType: "BET_FOLIO_SOLO",
//   //   bets: 0
//   // },
//   // {
//   //   mintId: 2,
//   //   mintName: "BetFolio Extended Solo Prediction Pass",
//   //   tokenValue: 5,
//   //   contract: soloExtendedNft,
//   //   contractType: "EXTENDED_SOLO",
//   //   bets: 0

//   // },
//   // {
//   //   mintId: 3,
//   //   mintName: "BetFolio Multichoice Predictions Pass",
//   //   tokenValue: 1.5,
//   //   contract: multiChoiceNft,
//   //   contractType: "BET_FOLIO_MULTI",
//   //   bets: 0
//   // },
//   {
//     mintId: 4,
//     mintName: "Solo Time Extension NFT - 1 bet",
//     tokenValue: 4,
//     contract: soloTimeExtensionNFT_01_BETS_ADDRESS,
//     contractType: "SOLO",
//     bets: 1
//   },
//   {
//     mintId: 5,
//     mintName: "Solo Time Extension NFT - 3 bets",
//     tokenValue: 8,
//     contract: soloTimeExtensionNFT_03_BETS_ADDRESS,
//     contractType: "SOLO",
//     bets: 3
//   },
//   {
//     mintId: 6,
//     mintName: "Solo Time Extension NFT - 10 bets",
//     tokenValue: 20,
//     contract: soloTimeExtensionNFT_10_BETS_ADDRESS,
//     contractType: "SOLO",
//     bets: 10
//   },
//   {
//     mintId: 7,
//     mintName: "Multi-choice Time Extension NFT - 1 bet",
//     tokenValue: 10,
//     contract: multiTimeExtensionNFT_01_BETS_ADDRESS,
//     contractType: "MULTI",
//     bets: 1
//   },
//   {
//     mintId: 8,
//     mintName: "Multi-choice Time Extension NFT - 20 bets",
//     tokenValue: 25,
//     contract: multiTimeExtensionNFT_20_BETS_ADDRESS,
//     contractType: "MULTI",
//     bets: 20
//   }
// ];

// export const adConfig = {
//   Home: {
//     ids: {
//       desktop: ["ct_cRCPxDcJyEZ","ct_cxSSvNRVVyl"],
//       mobile: ["ct_cv9ftlceqwI", "ct_cYFJEf7zTuB"],
//     },
//     dimensions: {
//       desktop:["728x90","728x90"],
//       mobile: ["320x100","320x100"],
//     },
//   },
//   AboutUs: {
//     ids: ["ct_cRCPxDcJyEZ", "ct_cgzpoP78n9u", "ct_cKx5LVVM79b","ct_cv9ftlceqwI"],
//     dimensions: ["728x90", "160x600", "160x600","320x100"],
//   },
//   ContactUs: {
//     ids: ["ct_cRCPxDcJyEZ", "ct_cgzpoP78n9u", "ct_cKx5LVVM79b","ct_cv9ftlceqwI"],
//     dimensions: ["728x90", "160x600", "160x600","320x100"],
//   },
//   PredictionDetail: {
//     ids: ["ct_cRCPxDcJyEZ", "ct_cDH9jc2HYxX", "ct_cv9ftlceqwI", "ct_cXLTfT9UNz7","ct_cYFJEf7zTuB"],
//     dimensions: ["728x90", "300x250", "320x100","728x90","320x100"],
//   },
//   Redeem: {
//     ids: ["ct_cRCPxDcJyEZ", "ct_cgzpoP78n9u", "ct_cKx5LVVM79b","ct_cv9ftlceqwI"],
//     dimensions: ["728x90", "160x600", "160x600","320x100"],
//   },
//   TransactionManagement: {
//     ids: ["ct_cRCPxDcJyEZ", "ct_cgzpoP78n9u", "ct_cKx5LVVM79b","ct_cv9ftlceqwI"],
//     dimensions: ["728x90", "160x600", "160x600","320x100"],
//   },
//   RefferalBoard: {
//     ids: ["ct_cRCPxDcJyEZ", "ct_cgzpoP78n9u", "ct_cKx5LVVM79b","ct_cv9ftlceqwI"],
//     dimensions: ["728x90", "160x600", "160x600","320x100"],
//   },
//   LeaderBoard: {
//     ids: ["ct_cRCPxDcJyEZ", "ct_cgzpoP78n9u", "ct_cKx5LVVM79b","ct_cv9ftlceqwI"],
//     dimensions: ["728x90", "160x600", "160x600","320x100"],
//   },
//   Footer:{

//     ids: ["ct_c49KhtWeZwp","ct_ckHaHVgOXrV"],
//     dimensions: ["728x90","320x100"],
//   },
//   profileDetail: {
//     ids: ["ct_cRCPxDcJyEZ", "ct_cgzpoP78n9u", "ct_cKx5LVVM79b","ct_cv9ftlceqwI"],
//     dimensions: ["728x90", "160x600", "160x600","320x100"],
//   },
// };

// export const chainConfig = {
//   chainNamespace: CHAIN_NAMESPACES.EIP155,
//   chainId: "0x89",
//   rpcTarget: "https://polygon-mainnet.g.alchemy.com/v2/0x4V4BZOZaDOcVRat5L6CQTcA2QTYWqD",
//   displayName: "Polygon Mainnet",
//   blockExplorerUrl: "https://polygon.etherscan.io",
//   ticker: "MATIC",
//   tickerName: "Polygon",
// };

// export const web3AuthNetwork = WEB3AUTH_NETWORK.SAPPHIRE_MAINNET;

//*************************** Live Ends ***********************************************************===>

// *************************** staging==Start ***********************************************************===>

// export const apiUrl = "https://betfolio-api.ecomempire.in/api/v1"
// export const baseUrl = "https://betfolio-api.ecomempire.in/"
// export const chainId = 137;
// export const appUrl = "https://betfolio.ecomempire.in"
// export const tokenAddress = "0x04BcEfE854Bcfee99d6921249db6F83090b5B2b8";
// export const consttransactionurl = "https://polygonscan.com/tx/";
// export const metamaskUrl = "https://metamask.app.link/dapp/betfolio.ecomempire.in/"
// export const divideBy = 1000000;
// export const decimalTo = 6;
// export const betCounterAddress = "0x8627e69605adc9317A200A79536577B2c6F1DfDf"
// export const soloCounterAddress = "0x6585bf144E4f1d1763eF63B17E836f54C2Eb3eBB"
// export const soloNft = "0x177Cb9f5Fe566dA86443f54312c0e4F4F9285ce0"
// export const soloExtendedNft = "0xBCbb8Ed8Ac13972cC46575D63A080537e233D9A4"
// export const groupNft = "0x565b610BBEf2ECADCfdc175313190f0143F6E5CB"
// export const multiChoiceNft = "0x85e8Ae1B4d145D7707EA926B21983df7E959F4cb"
// export const multiChoiceBetCounter = "0x8F3EFFFD422CD5b1d9fe8497c6f63f05B88F2640"
// export const folioContractAddress = '0x85114f24b621157FFd5b4ad9CEbB60333E7E1da4';
// export const nftMinterAddress = "0xc179327de7d1039f3d2fd72e24e9d574f12b7fa5"
// export const timeExtenderAddress = "0x236a25478efFd93B35A255483d2B0543ab41d797"
// export const WEB3_PROVIDER = "https://polygon-mainnet.g.alchemy.com/v2/0x4V4BZOZaDOcVRat5L6CQTcA2QTYWqD"
// export const NftBuyContractAddress = '0x91a8989AA56667717828737595a0dDb0f6cDBDA0';

// export const soloTimeExtensionNFT_01_BETS_ADDRESS = "0x4159a4bC57C0D8627117F24f1C5670eA4DF30e6C";
// export const soloTimeExtensionNFT_03_BETS_ADDRESS = "0x998874c33358A38bcf40a8304C17B55560747e2f";
// export const soloTimeExtensionNFT_10_BETS_ADDRESS = "0x511687B1c62F22C932C89DCE45D00bf832DB92D9";
// export const multiTimeExtensionNFT_01_BETS_ADDRESS = "0x5A1419553D4F7FB656F72216BCA38F59bFd78BD4";
// export const multiTimeExtensionNFT_20_BETS_ADDRESS = "0x5F508D3D5420432e0ac1089264b34BDB0B3B7957";
// export const nftAllContract = "0xb6a1bEE211aBF58956B07e8B82484936d0EC74ae";
// export const discountContract = "0x6F41130324a848aB12101B5ed0826753E3a34d47";
// export const PAYPAL_CLIENT_ID="AVbooUulK3qcKJ98dna2bpNQoX0sOw9eeAkCxsTZdjodpoXEdZBvqdMC6VLkRWSL_3q1PCw6KLOmI82h";
// export const nftBundlingContract = "0x76Af0D63f4816cb18c2368F716003393b230CdCC";
// export const nftBundlingListingFee = 0.2;
// export const nftBundlingBuyingFee = 0.2;
// export const nftBundlingRelistingFee = 0.2;
// export const nftBundlingUpdatePricingFee = 0.1;
// export const clientId = "BM3eS4IOgOsegcA96wwSu09whiVftpOaK3uTYr14sA7o2ehEeS96x1SSmSJBFJ34po3q8vmJGBgbL-Cy68ImX2w" //MAINNET staging
// // export const clientId = "BKW6_egDTCqEHhLy2qBvPbnWF4qVLbdJk3gWjz6AtwncL9fNW7B1pkXtyWHVO-SMPl3Mu2zPCoumR_9u8xgPWmY" //test ajit

// export const mintNftsList = [
//   // {
//   //     mintId: 0,
//   //     mintName: "Group NFT",
//   //     tokenValue: 4,
//   //     contract:"0xE99D8d709679D76B7465fC7c3ff57b862BaFD547",
//   //     contractType:"GROUP",
//   //     bets:0
//   // },
//   // {
//   //     mintId: 1,
//   //     mintName: "BetFolio Solo Prediction Pass",
//   //     tokenValue: 3.8,
//   //     contract:"0x7408BbB4C77Ad70EA827901C8FAC9a4f61F77371",
//   //     contractType:"BET_FOLIO_SOLO",
//   //     bets:0
//   // },
//   // {
//   //     mintId: 2,
//   //     mintName: "BetFolio Extended Solo Prediction Pass",
//   //     tokenValue: 11.4,
//   //     contract:"0xBd4fECE74B7855D894666163EE294Da04249b3a5",
//   //     contractType:"EXTENDED_SOLO",
//   //     bets:0

//   // },
//   // {
//   //     mintId: 3,
//   //     mintName: "BetFolio Multichoice Predictions Pass",
//   //     tokenValue: 2.5,
//   //     contract:"0xd3FEaF810fC9bE017B0DeFC8458d7D1b73e0A0a8",
//   //     contractType:"BET_FOLIO_MULTI",
//   //     bets:0
//   // },
//   {
//     mintId: 4,
//     mintName: "Solo Time Extension NFT - 1 bet",
//     tokenValue: 1.5,
//     contract: soloTimeExtensionNFT_01_BETS_ADDRESS,
//     contractType: "SOLO",
//     bets: 1
//   },
//   {
//     mintId: 5,
//     mintName: "Solo Time Extension NFT - 3 bets",
//     tokenValue: 3,
//     contract: soloTimeExtensionNFT_03_BETS_ADDRESS,
//     contractType: "SOLO",
//     bets: 3
//   },
//   {
//     mintId: 6,
//     mintName: "Solo Time Extension NFT - 10 bets",
//     tokenValue: 7,
//     contract: soloTimeExtensionNFT_10_BETS_ADDRESS,
//     contractType: "SOLO",
//     bets: 10
//   },
//   {
//     mintId: 7,
//     mintName: "Multi-choice Time Extension NFT - 1 bet",
//     tokenValue: 2,
//     contract: multiTimeExtensionNFT_01_BETS_ADDRESS,
//     contractType: "MULTI",
//     bets: 1
//   },
//   {
//     mintId: 8,
//     mintName: "Multi-choice Time Extension NFT - 20 bets",
//     tokenValue: 10,
//     contract: multiTimeExtensionNFT_20_BETS_ADDRESS,
//     contractType: "MULTI",
//     bets: 20
//   }
// ];
// *************************** staging Ends ***********************************************************===>


// // Testing Amoy start
// export const apiUrl = "https://test-api.betfolio.co/api/v1"
// export const baseUrl = "https://test-api.betfolio.co/"
// export const appUrl = "https://test.betfolio.co"
// export const chainId = 80002;
// export const tokenAddress = "0x1c7D4AD5dA48DC439510e54C3b4dd12b727bd821";
// export const consttransactionurl = "https://amoy.polygonscan.com/tx/";
// export const divideBy = 1000000;
// export const decimalTo = 6;
// export const metamaskUrl = "https://metamask.app.link/dapp/betfolio.ecomempire.in/"
// export const betCounterAddress = "0x7093713ef8230cD4B697210F724F1C586EdA4a8A"
// export const soloCounterAddress = "0x236a25478efFd93B35A255483d2B0543ab41d797"
// export const soloNft = "0x23CD4E0DD786F47cDF0c20c1818ED89a8004d221"
// export const soloExtendedNft = "0x6c4472ED330e4E2e06381B07AF05d6416b2389a0"
// export const groupNft = "0x63558987389595a3826f972433c577b6D879EBeA"
// export const multiChoiceNft = "0x4f6C4FaC6E8F56931F8DeC2B7698f5890F89830A"
// export const multiChoiceBetCounter = "0x120bB9e25a47FfF9711f741D89980cE469CA0B2e"
// export const folioContractAddress = '0x3F276960F628d1A8C2749dA801d75e9040f1D081';
// export const nftMinterAddress = "0x245E344c445E119fA36F9e7C2f665dCB0d635c4A"
// export const timeExtenderAddress = "0x9aEE12EEd25f4A35039eAf96BfB93FD909f2B740"
// export const WEB3_PROVIDER = "https://polygon-amoy.g.alchemy.com/v2/0x4V4BZOZaDOcVRat5L6CQTcA2QTYWqD"

// export const soloTimeExtensionNFT_01_BETS_ADDRESS = "0x4BB05cF2787978F8dfa568837AF82AC3e125dfEC";
// export const soloTimeExtensionNFT_03_BETS_ADDRESS = "0xF0Ac953Bca9716B665f43b977Ab394c071CE688c";
// export const soloTimeExtensionNFT_10_BETS_ADDRESS = "0x0f06C5189E0Ce18497bB3Cf6e202b4221927DD92";
// export const multiTimeExtensionNFT_01_BETS_ADDRESS = "0xD832F9cfD810a2C0645cFe24E99789316e46ecfB";
// export const multiTimeExtensionNFT_20_BETS_ADDRESS = "0x9BB7D468d8fee91b015A40c179489a2D6C940B30";
// export const NftBuyContractAddress = '0xf5F9E13f095a5B3ff3294579Ff0912E3c3f12892';
// export const discountContract = "0x413EEbD11d53B638c53769F345B9e7B6f40892Be";
// export const nftAllContract = "0xf5F9E13f095a5B3ff3294579Ff0912E3c3f12892";
// export const nftBundlingContract = "0x1d4bA1A437BD5fa8110515EE35cC36F7101fD3DB";
// export const nftBundlingListingFee = 0.2;
// export const nftBundlingBuyingFee = 0.2;
// export const nftBundlingRelistingFee = 0.2;
// export const nftBundlingUpdatePricingFee = 0.1;



// export const clientId = "BDbKcWJM37MRySHMQO-qvrWIUSPOZ5Ew5yYNGZPUBhfRWUZNdREPnUTLpGXwPlJlQASgDTilUW569tLAqQNKjBI" // Web3Auth clientId    TESTNET
// export const PAYPAL_CLIENT_ID = "AQ8zN4Cpi5vPCg_Tw0pxNrvrxRlDdQ70OAMI5xEIEmsyi0Y4MYwMGKMK_J7-xOQF9LHhzrV-gPGrGa4n";
// // testing sepolia End




// export const mintNftsList = [

//   // {
//   //   mintId: 0,
//   //   mintName: "Group NFT",
//   //   tokenValue: 4,
//   //   contract: groupNft,
//   //   contractType: "GROUP",
//   //   bets: 0
//   // },
//   // {
//   //   mintId: 1,
//   //   mintName: "BetFolio Solo Prediction Pass",
//   //   tokenValue: 1.5,
//   //   contract: soloNft,
//   //   contractType: "BET_FOLIO_SOLO",
//   //   bets: 0
//   // },
//   // {
//   //   mintId: 2,
//   //   mintName: "BetFolio Extended Solo Prediction Pass",
//   //   tokenValue: 5,
//   //   contract: soloExtendedNft,
//   //   contractType: "EXTENDED_SOLO",
//   //   bets: 0

//   // },
//   // {
//   //   mintId: 3,
//   //   mintName: "BetFolio Multichoice Predictions Pass",
//   //   tokenValue: 1.5,
//   //   contract: multiChoiceNft,
//   //   contractType: "BET_FOLIO_MULTI",
//   //   bets: 0
//   // },
//   {
//     mintId: 4,
//     mintName: "Solo Time Extension NFT - 1 bet",
//     tokenValue: 0.75,
//     contract: soloTimeExtensionNFT_01_BETS_ADDRESS,
//     contractType: "SOLO",
//     bets: 1
//   },
//   {
//     mintId: 5,
//     mintName: "Solo Time Extension NFT - 3 bets",
//     tokenValue: 1.5,
//     contract: soloTimeExtensionNFT_03_BETS_ADDRESS,
//     contractType: "SOLO",
//     bets: 3
//   },
//   {
//     mintId: 6,
//     mintName: "Solo Time Extension NFT - 10 bets",
//     tokenValue: 3.5,
//     contract: soloTimeExtensionNFT_10_BETS_ADDRESS,
//     contractType: "SOLO",
//     bets: 10
//   },
//   {
//     mintId: 7,
//     mintName: "Multi-choice Time Extension NFT - 1 bet",
//     tokenValue: 1,
//     contract: multiTimeExtensionNFT_01_BETS_ADDRESS,
//     contractType: "MULTI",
//     bets: 1
//   },
//   {
//     mintId: 8,
//     mintName: "Multi-choice Time Extension NFT - 20 bets",
//     tokenValue: 5,
//     contract: multiTimeExtensionNFT_20_BETS_ADDRESS,
//     contractType: "MULTI",
//     bets: 20
//   },
//   // {
//   //   mintId: 9,
//   //   mintName: "25% Discount",
//   //   tokenValue: 5000,
//   //   contract: "0x21A0D335e1416057eE34632D069a22F3E87d0997",
//   //   contractType: "DISCOUNT",
//   //   // bets: 20
//   // },
//   // {
//   //   mintId: 10,
//   //   mintName: "50% Discount",
//   //   tokenValue: 10000,
//   //   contract: "0x8dD65Fa956b581A332872d173CF6bF123aa5ef30",
//   //   contractType: "DISCOUNT",
//   //   // bets: 20
//   // }
// ];

// export const adConfig = {
//   Home: {
//     ids: {
//       desktop: ["ct_cD4FW82tXoH", "ct_cnppLsvxbZo"],
//       mobile: ["ct_c43z8P1Kuho", "ct_cjZxR4KyAD5"],
//     },
//     dimensions: {
//       desktop: ["728x90", "728x90"],
//       mobile: ["320x100", "320x100"],
//     },
//   },
//   AboutUs: {
//     ids: ["ct_cD4FW82tXoH", "ct_c2GLSrJyPLh", "ct_cHeIvmv8mro", "ct_c43z8P1Kuho"],
//     dimensions: ["728x90", "160x600", "160x600", "320x100"],
//   },
//   ContactUs: {
//     ids: ["ct_cD4FW82tXoH", "ct_c2GLSrJyPLh", "ct_cHeIvmv8mro", "ct_c43z8P1Kuho"],
//     dimensions: ["728x90", "160x600", "160x600", "320x100"],
//   },
//   PredictionDetail: {
//     ids: ["ct_cnppLsvxbZo", "ct_ce8CXiDq82e", "ct_c43z8P1Kuho", "ct_cNQ5teLdZ2J", "ct_cjZxR4KyAD5"],
//     dimensions: ["728x90", "300x250", "320x100", "728x90", "320x100"],
//   },
//   Redeem: {
//     ids: ["ct_cD4FW82tXoH", "ct_c2GLSrJyPLh", "ct_cHeIvmv8mro", "ct_c43z8P1Kuho"],
//     dimensions: ["728x90", "160x600", "160x600", "320x100"],
//   },
//   TransactionManagement: {
//     ids: ["ct_cD4FW82tXoH", "ct_c2GLSrJyPLh", "ct_cHeIvmv8mro", "ct_c43z8P1Kuho"],
//     dimensions: ["728x90", "160x600", "160x600", "320x100"],
//   },
//   RefferalBoard: {
//     ids: ["ct_cD4FW82tXoH", "ct_c2GLSrJyPLh", "ct_cHeIvmv8mro", "ct_c43z8P1Kuho"],
//     dimensions: ["728x90", "160x600", "160x600", "320x100"],
//   },
//   LeaderBoard: {
//     ids: ["ct_cD4FW82tXoH", "ct_c2GLSrJyPLh", "ct_cHeIvmv8mro", "ct_c43z8P1Kuho"],
//     dimensions: ["728x90", "160x600", "160x600", "320x100"],
//   },
//   Footer: {

//     ids: ["ct_cpvwxkxE3K9", "ct_c4fha7DixCM"],
//     dimensions: ["728x90", "320x100"],
//   },
//   profileDetail: {
//     ids: ["ct_cD4FW82tXoH", "ct_c2GLSrJyPLh", "ct_cHeIvmv8mro", "ct_c43z8P1Kuho"],
//     dimensions: ["728x90", "160x600", "160x600", "320x100"],
//   },
// };


// export const chainConfig = {
//   chainNamespace: CHAIN_NAMESPACES.EIP155,
//   chainId: "0x13882", // hex of 80002, polygon testnet
//   // rpcTarget: "https://rpc.ankr.com/polygon_amoy",
//   rpcTarget: "https://polygon-amoy.g.alchemy.com/v2/0x4V4BZOZaDOcVRat5L6CQTcA2QTYWqD",
//   displayName: "Polygon Amoy Testnet",
//   blockExplorerUrl: "https://amoy.polygonscan.com/",
//   ticker: "POL",
//   tickerName: "Polygon Ecosystem Token",
//   logo: "https://cryptologos.cc/logos/polygon-matic-logo.png",
// };

// export const web3AuthNetwork = WEB3AUTH_NETWORK.SAPPHIRE_DEVNET;

/////////////////// Test confiration ends /////////////




const environment = "TEST";
// const environment = "LIVE";
// const environment = "STAGING";



let config;
switch (environment) {
  case "LIVE":
    config = await import("./live.js");
    break;
  case "TEST":
    config = await import("./test.js");
    break;
  default:
    config = await import("./staging.js");
    break;
}

export const { apiUrl, baseUrl, appUrl, chainId, tokenAddress, consttransactionurl, divideBy, decimalTo, metamaskUrl, betCounterAddress, soloCounterAddress, soloNft, soloExtendedNft, groupNft, multiChoiceNft, multiChoiceBetCounter, folioContractAddress, nftMinterAddress, timeExtenderAddress, WEB3_PROVIDER, soloTimeExtensionNFT_01_BETS_ADDRESS, soloTimeExtensionNFT_03_BETS_ADDRESS, soloTimeExtensionNFT_10_BETS_ADDRESS, multiTimeExtensionNFT_01_BETS_ADDRESS, multiTimeExtensionNFT_20_BETS_ADDRESS, NftBuyContractAddress, discountContract,discountContractNew, nftAllContract, nftBundlingContract, nftBundlingListingFee, nftBundlingBuyingFee, nftBundlingRelistingFee, nftBundlingUpdatePricingFee, clientId, PAYPAL_CLIENT_ID, mintNftsList, adConfig, chainConfig, web3AuthNetwork , aiGatewayAddress  } = config;

export const gasFee = 21000
export const gasFeePercent = 1.9
export const folioDecimals = 18;
export const httpproviderPOLY = "https://polygon-mainnet.g.alchemy.com/v2/0x4V4BZOZaDOcVRat5L6CQTcA2QTYWqD"


export const indexcubeMetamaskUrl = "https://metamask.app.link/dapp/betfolio.co//"
export const Unauthorized = () => {
  swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
    localStorage.clear()
    window.location.href = "/";
    console.log('OK button clicked after error alert');
  })
}


export function capitalizeFirstLetter(str) {
  console.log("str", str, str.toString());
  str = str.toString();
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const formatWalletAddress = (address) => {
  if (!address) return '';
  const firstPart = address.slice(0, 4);
  const lastPart = address.slice(-4);
  return `${firstPart}...${lastPart}`;
};




export const commissionArray = [{ amountmin: 0, amountmax: 1000, commission: 3 }, {
  amountmin: 1001, amountmax: 5000, commission: 2,
}, { amountmin: 5001, amountmax: 10000, commission: 1 }, { amountmin: 10001, amountmax: 1000000000000, commission: 0.5 }]





export const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig },
});



export const web3auth = new Web3Auth({
  clientId,
  web3AuthNetwork: web3AuthNetwork,
  privateKeyProvider,
  uiConfig: {
    appName: "BetFolio",
    appUrl: appUrl,
    logoDark: `${baseUrl}email-image/logo-betfolio.png`,
    logoLight: `${baseUrl}email-image/logo-betfolio.png`
  },
  sessionTime: 86400 * 7,
});

export const walletServicesPlugin = new WalletServicesPlugin();

export async function showCheckout() {
  await walletServicesPlugin.showCheckout({
    tokenList: ['POL', 'USDT'],
    // receiveWalletAddress: walletAddress,
    show: true
  });
}

export const soloTimeExtensionNFT_01_MAX_BETS = 1;
export const soloTimeExtensionNFT_03_MAX_BETS = 3;
export const soloTimeExtensionNFT_10_MAX_BETS = 10;
export const multiTimeExtensionNFT_01_MAX_BETS = 1;
export const multiTimeExtensionNFT_20_MAX_BETS = 20;